<template>
  <div class="Create-invoicing_root">
    <h2 class="Create-invoicing_title font-medium text-[18px] leading-[21px] uppercase text-left">Create invoice</h2>
    <v-form
      class="Create-invoicing_form"
      ref="form"
      autocomplete="false"
    >
      <div class="Create-invoicing_input">
        <TextFieldInput
          name="payee_email"
          label="payee"
          hideDetails
          placeholder="Email address"
          :value="invoiceForm.payee_email"
          @input="handleInput(arguments[0], 'payee_email')"
          :rules="[...inputRules.required, ...inputRules.email]"
        />
      </div>
      <div class="Create-invoicing_input">
        <div class="Create-invoicing_container">
          <TextFieldInput
            type="number"
            name="invoice_amount"
            label="Amount"
            hideDetails
            placeholder="Your amount"
            :value="invoiceForm.invoice_amount"
            @input="handleInput(arguments[0], 'invoice_amount')"
            :rules="[...inputRules.required]"
          />
          <Select
            componentName="VAutocomplete"
            class="invoicing-create-amount-field"
            v-if="invoiceOrderProcessingRules.length > 0"
            :items="invoiceOrderProcessingRules"
            :value="invoiceForm.invoice_currency"
            name="invoice_currency"
            @change="handleSelect(arguments[0], 'invoice_currency')"
          />
        </div>
      </div>
      <div class="Create-invoicing_input">
        <Select
          :value="PAYMENT_TYPES[0]"
          :items="PAYMENT_TYPES"
          name="invoice_type"
          label="Payment Type"
          @change="handleType"
        />
      </div>
      <div class="Create-invoicing_input Create-invoicing_inputMargin Create-invoicing_textarea">
        <TextArea
          name="invoice_comment"
          label="description"
          hideDetails
          placeholder="Your description"
          @input="handleInput(arguments[0], 'invoice_comment')"
          :value="invoiceForm.invoice_comment"
        />
      </div>
      <OutlineButton
        class="outline-button-default outline-button-full-width outline-button"
        text="create invoice"
        @click="handleCreateInvoice"
      />
    </v-form>
  </div>
</template>

<script>
import PAYMENT_TYPES from './PaymentTypes';
import {mapState, mapActions, mapGetters} from 'vuex';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import TextArea from '@/components/_common/FormElements/TextArea/TextArea';
import Select from '@/components/_common/FormElements/Select/Select';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import {validateForm} from '@/mixins/validateForm';
import inputRules from '@/utils/inputRules';

export default {
  data() {
    return {
      inputRules,
      PAYMENT_TYPES,
      invoiceForm: {
        payee_email: '',
        invoice_amount: '',
        invoice_currency: '',
        invoice_type: 'COINPAY',
        invoice_comment: '',
      },
    };
  },
  components: {
    TextFieldInput,
    Select,
    TextArea,
    OutlineButton,
  },
  mixins: [validateForm],
  computed: {
    ...mapState('user', ['_userModuleLoaded']),
    ...mapGetters('user', ['invoiceOrderProcessingRules']),
  },
  methods: {
    ...mapActions('invoicing', ['apiCreateInvoice']),
    handleInput(value, field) {
      this.invoiceForm[field] = value;
    },
    handleSelect(value) {
      this.invoiceForm[value.field] = value.value;
    },
    handleType(value) {
      this.invoiceForm.invoice_type = value.value;
    },
    handleCreateInvoice() {
      this.validateForm().then(() => {
        this.apiCreateInvoice(this.invoiceForm).then(() => {
          this.invoiceForm.payee_email = '';
          this.invoiceForm.invoice_amount = '';
          this.invoiceForm.invoice_comment = '';
        });
      });
    },
  },
  watch: {
    _userModuleLoaded: {
      handler(newVal) {
        if (newVal) this.invoiceForm.invoice_currency = this.invoiceOrderProcessingRules?.[0]?.value;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import './Create.scss';
</style>
